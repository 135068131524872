import React, { ReactElement } from 'react';
import Tooltip from 'components/Tooltip';

import Amazon from 'assets/images/amazon.svg';
import Instagram from 'assets/images/instagram.svg';
import ITunes from 'assets/images/itunes.svg';
import Mail from 'assets/images/mail.svg';
import Spotify from 'assets/images/spotify.svg';
import Youtube from 'assets/images/youtube.svg';


type IconType = 'amazon' | 'instagram' | 'itunes' | 'email' | 'spotify' | 'youtube';

const icons: { [key in IconType]: ReactElement } = {
  'amazon': <img className="h-6 w-6 lg:h-8 lg:w-8" src={Amazon} />,
  'instagram': <img className="h-6 w-6 lg:h-8 lg:w-8" src={Instagram} />,
  'itunes': <img className="h-6 w-6 lg:h-8 lg:w-8" src={ITunes} />,
  'email': <img className="h-6 w-6 lg:h-8 lg:w-8" src={Mail} />,
  'spotify': <img className="h-6 w-6 lg:h-8 lg:w-8" src={Spotify} />,
  'youtube': <img className="h-6 w-6 lg:h-8 lg:w-8" src={Youtube} />
};

interface SocialLink {
  type: IconType;
  url: string;
  tooltip?: string;
}

interface SocialsProps {
  links: SocialLink[];
  keyPrefix: string;
}

const Socials = (props: SocialsProps) => (
  <div className="space-x-4 pt-2 pl-2 pr-2 bg-gray-100 bg-opacity-10 rounded">
    {props.links.map((link, index) => (
      <a
        key={`social-icon-${props.keyPrefix}-${index}`} href={link.url}
        target="_blank"
        rel="noopener norefferer"
      >
        <Tooltip text={link.tooltip || ''}>
          {icons[link.type]}
        </Tooltip>
      </a>
    ))}
  </div>
);

export default Socials;
export {
  SocialsProps,
  SocialLink,
  IconType
}
